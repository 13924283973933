import { isBooleanTrue } from './helper'

export type Environment = 'development' | 'preview' | 'staging' | 'production' | string

export const RELEASE = process.env.NEXT_PUBLIC_RELEASE
  || process.env.VERCEL_GIT_COMMIT_SHA
  || process.env.VERCEL_GIT_COMMIT_SHA
  || process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
  || 'local'

export const ANALYTICS_ENABLED = isBooleanTrue(process.env.NEXT_PUBLIC_ANALYTICS_ENABLED ?? 'false')
export const APP_PROTOCOL = process.env.NEXT_PUBLIC_APP_PROTOCOL
export const COOKIE_SECURE = isBooleanTrue(process.env.NEXT_PUBLIC_COOKIE_SECURE ?? 'true')
export const COOKIE_FUNCTIONAL_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_FUNCTIONAL_DOMAIN
export const COOKIE_SHARED_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_SHARED_DOMAIN
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT as Environment
export const FB_APP_ID = process.env.NEXT_PUBLIC_FB_APP_ID
export const ID_OAUTH_ID = process.env.NEXT_PUBLIC_ID_OAUTH_ID
export const ID_URL = process.env.NEXT_PUBLIC_ID_URL
export const MAP_TILER_KEY = process.env.NEXT_PUBLIC_MAP_TILER_KEY
export const PIN_SHARING_URL = process.env.NEXT_PUBLIC_PIN_SHARING_URL || ''
export const PLAUSIBLE_DOMAINS = process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAINS
export const PLAUSIBLE_CLIENT_API_HOST = process.env.NEXT_PUBLIC_PLAUSIBLE_CLIENT_API_HOST
export const PLAUSIBLE_CLIENT_API_PATH = process.env.NEXT_PUBLIC_PLAUSIBLE_CLIENT_API_PATH
export const PUBLIC_URL = process.env.NEXT_PUBLIC_PUBLIC_URL
export const SENTRY_CLIENT_DSN = process.env.NEXT_PUBLIC_SENTRY_CLIENT_DSN
export const SENTRY_EDGE_DSN = process.env.NEXT_PUBLIC_SENTRY_EDGE_DSN
export const TWITTER_SITE = process.env.NEXT_PUBLIC_TWITTER_SITE

/**
 * @deprecated Use ServerConfig.IDENTITY_SERVER_HOST instead. API calls to the identity server
 * should not be done from the browser. Instead create an API route here in next.
 */
export const ID_API_URL = process.env.NEXT_PUBLIC_ID_API_URL

/**
 * @deprecated Use ServerConfig.CORE_SERVICE_SERVER_HOST instead. API calls to the core service server
 * should not be done from the browser. Instead create an API route here in next.
 */
export const CS_API_URL = process.env.NEXT_PUBLIC_CS_API_URL

/**
 * @deprecated Use ClientConfig.ENVIRONMENT instead
 */
export const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV
